/* stylelint-disable no-descending-specificity */
@import "../../styles/variables";

.location-section {
    position: relative;
    padding-left: 2rem;

    .location-container {
        padding-bottom: 1rem;

        .header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 5rem;
            width: 50%;

            .logo-container {
                width: 14rem;
            }

            .auth-action-container {
                display: inline-block;
            }
        }
    }

    .backgroud-container {
        position: absolute;
        top: 0;
        left: calc(50% + 5rem);
        right: 0;
        background-image: url("../../../public/assets/images/breakfast.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100%;
        height: 100%;
    }

    .search-container {
        margin-top: 4rem;
        margin-bottom: 2.5rem;
        width: 50%;

        h2 {
            font-size: $fs-900;
            font-weight: $fw-700;
        }

        p {
            font-size: $fs-500;
            font-weight: $fw-500;
        }

        .location-search-container {
            display: flex;
            margin-top: 4.5rem;
            border-radius: 0;

            .input-wrapper {
                width: 100%;
                border: $border-size solid $grey-4;

                input {
                    line-height: 4.5rem;

                    &:focus {
                        .input-wrapper {
                            border: $border-size solid $primary;
                        }
                    }
                }
            }

            button > span {
                font-size: $fs-400;
                font-weight: $fw-700;
            }
        }

        .popular-city-header {
            margin-top: 3rem;
            font-weight: $fw-500;
            color: $grey-2;
            font-size: $fs-300;
        }

        ul {
            margin-top: 1rem;

            li {
                display: inline-block;
                font-size: $fs-300;
                margin-right: 0.6rem;
                font-weight: $fw-700;
            }

            li:nth-of-type(odd) > a {
                color: $grey-1;
            }

            li:nth-of-type(even) > a {
                color: $grey-3;
            }
        }
    }
}

.features-section {
    display: flex;
    align-items: center;
    background-color: $brown;
    padding-bottom: 2rem;
    height: 32rem;

    .features-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 4rem;

        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .feature-container {
            color: $white;

            .feature-img-wrapper > img {
                height: 15rem;
                margin-bottom: 3rem;
            }

            h2 {
                color: $white;
                font-weight: $fw-700;
                margin-bottom: 0.8rem;
            }

            p {
                font-weight: $fw-500;
                text-align: center;
            }
        }
    }
}

.mobile-app-section {
    width: 100%;

    .mobile-app-container {
        display: flex;
        justify-content: space-between;

        .info-container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {
                font-size: $fs-900;
                font-weight: $fw-700;
            }

            p {
                font-size: $fs-400;
                font-weight: $fw-500;
                margin-top: 1.5rem;
                margin-bottom: 4rem;
            }

            .app-link-container {
                display: flex;

                img {
                    width: 50%;
                    transition: all 0.1s ease-out;
                }

                img:first-child {
                    margin-right: 1.5rem;
                }

                img:hover {
                    transform: scale(1.05);
                    cursor: pointer;
                }
            }
        }

        .img-container {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            img {
                width: 42%;
            }

            img:nth-child(2) {
                margin-top: 6rem;
            }
        }
    }
}

.footers-section {
    display: flex;
    flex-direction: column;
    background-color: $black;

    .footer-primary-section {
        display: flex;
        justify-content: space-between;
        color: $white;
        margin-top: 3rem;
        margin-bottom: 1rem;

        h3 {
            color: $grey-3;
            font-weight: $fw-700;
            margin-bottom: 1.5rem;
        }

        ul {
            li {
                font-size: $fs-400;
                margin-bottom: 0.8rem;
            }
        }

        .footer-container {
            flex: 1;
        }

        .link-container {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            img {
                width: 15rem;
                transition: all 0.1s ease-out;
            }

            img:hover {
                transform: scale(1.05);
                cursor: pointer;
            }
        }
    }

    .footer-secondary-section {
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;

        h3 {
            color: $grey-3;
            font-weight: $fw-700;
            margin-bottom: 1.5rem;
        }

        .locations-container {
            display: flex;
            justify-content: space-between;

            ul {
                li {
                    color: $white;
                    font-size: $fs-400;
                    margin-bottom: 0.8rem;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .location-background-section,
    .features-section,
    .mobile-app-section,
    .footers-section {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media screen and (min-width: 768px) {
    .location-background-section,
    .features-section,
    .mobile-app-section,
    .footers-section {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media screen and (min-width: 992px) {
    .location-background-section,
    .features-section,
    .mobile-app-section,
    .footers-section {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media screen and (min-width: 1200px) {
    .location-background-section,
    .features-section,
    .mobile-app-section,
    .footers-section {
        padding: 0;
    }
}
