@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&family=Quicksand:wght@300;400;500;600;700&display=swap");

/* colors */
$primary: #fc8019;
$brown: #2b1e16;
$black: #000;
$grey-1: #282c3f;
$grey-2: #686b78;
$grey-3: #9c9da1;
$grey-4: #d5d6d8;
$white: #fff;
$link: #5d8ed5;
$background-dark: #171a29;
$background-dark-1: #3d4152;
$hr-height: 1px;
$info: #8a584b;
$warning: #db7c38;
$success: #48c479;

/* z-indices */
$scrollbar-z-index: 10;
$dropdown-z-index: 20;
$toaster-z-index: 30;
$backdrop-z-index: 40;
$modal-z-index: 50;

/* font-sizes */
$fs-900: 2.8rem;
$fs-800: 2.5rem;
$fs-700: 1.8rem;
$fs-600: 1.5rem;
$fs-500: 1.4rem;
$fs-400: 1.2rem;
$fs-300: 1rem;
$fs-200: 0.875rem;

/* font-weight */
$fw-700: 700;
$fw-600: 600;
$fw-500: 500;
$fw-400: 400;
$fw-300: 300;

/* font-families */
$ff-sans-serif: "Quicksand", sans-serif;

/* shadows */
$btn-shadow: 1px;
$btn-shadow-blur: 2px;
$btn-shadow-color: rgba($black, 0.3);
$box-shadow: 1px;

/* borders */
$border-size: 1px;

/* text */
$letter-spacing: 1px;
