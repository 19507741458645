@import "../../styles/variables";

.form-container {
    margin-left: 2.5rem;
    width: 50%;

    i {
        color: $grey-3;
        margin-top: 4rem;
        cursor: pointer;
    }

    .form-wrapper {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
    }

    .form-header-container {
        margin-bottom: 2rem;
    }

    h2 {
        font-weight: $fw-700;
        font-size: $fs-700;
    }

    .auth-btn {
        span {
            padding-left: 0.4rem;
            padding-right: 0.4rem;
        }
    }

    hr {
        width: 2.5rem;
        border: $border-size solid $black;
    }

    .form-icon {
        width: 6rem;
    }
}
