@import "../../styles/variables";

.mobile-only-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .img-container {
        width: 70%;
        margin-bottom: 4rem;
    }

    .info-container {
        width: 70%;
        text-align: center;

        h2 {
            font-size: $fs-800*1.4;
            font-weight: $fw-700;
            margin-bottom: 1rem;
            color: $grey-2;
        }

        p {
            font-size: $fs-700*1.2;
            font-weight: $fw-500;
            color: $grey-3;
        }
    }
}
