/* stylelint-disable no-descending-specificity */
@import "./variables";

.btn {
    border: none;
    border-radius: 0;
    background-color: $white;

    span {
        display: inline-block;
        padding: 0.8rem 2rem;
        font-size: $fs-300;
        font-weight: $fw-700;
    }

    &:hover {
        cursor: pointer;
    }
}

.btn-default {
    &:hover {
        span {
            color: $primary;
        }
    }
}

.btn-primary-default {
    span {
        color: $primary;
    }
}

.btn-primary-inverse {
    background-color: $primary;

    span {
        color: $white;
    }
}

.btn-primary {
    background-color: $primary;

    span {
        color: $white;
    }
}

.btn-dark {
    background-color: $black;

    span {
        color: $white;
    }

    &:hover {
        box-shadow: -$btn-shadow $btn-shadow $btn-shadow-blur $btn-shadow-color;
    }
}

.form-input {
    border: none;
    padding-left: 1.5rem;
    width: 100%;
    color: $grey-3;
    font-weight: $fw-700;
    font-size: $fs-400;

    &::placeholder {
        color: $grey-3;
        font-weight: $fw-700;
        font-size: $fs-400;
    }

    &:focus {
        outline: $border-size solid $primary;
        outline-offset: 0;
        caret-color: $primary;
    }
}

input {
    outline: none;
    border: none;
}
