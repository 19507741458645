/* stylelint-disable font-family-name-quotes */
@import "./variables";

*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: $ff-sans-serif;
    font-size: $fs-300;
    min-height: 100vh;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }

    // overflow: hidden;
}

h1,
h2,
h3,
h4 {
    color: $grey-1;
    font-weight: $fw-600;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: $black;
}

img,
picture {
    max-width: 100%;
    display: block;
}

input,
button,
textarea,
select {
    font: inherit;
}
